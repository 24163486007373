import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import { prop } from '_helpers/prop'
import { buildUrl } from 'build-url'

const useStyles = makeStyles({
  header: {
    fontSize: 14,
    marginBottom: 10,
  },
  chosen: {
    color: 'gray',
    marginBottom: 10,
  },
  choices: {
    marginTop: 10,
    '&>*:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

export const AutocompleteType = ({
  name,
  label,
  titleProperty = 'title',
  hint = null,
  endpoint,
  iriTemplate,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
}) => {
  const [text, setText] = useState('')
  const [choices, setChoices] = useState([])

  const handleTextChange = e => {
    const value = e.target.value
    setText(value)
  }

  const handleTextKeyUp = e => {
    const value = e.target.value

    if (value.length < 3) {
      setChoices([])

      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(endpoint, {
      queryParams: { text },
    })

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setChoices(response['autocomplete_results'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleChoose = e => {
    const uuid = e.target.getAttribute('uuid')
    const title = e.target.innerText

    setValue(name, {
      '@id': iriTemplate.replace(':uuid', uuid),
      translations: {
        [LANGS_IRI.PL]: {
          [titleProperty]: title,
        },
      },
    })

    setText('')
    setChoices([])
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        {translate(label?.text || label) +
          (validators && validators.includes('required') ? ' *' : '')}
      </div>
      <div className={classes.chosen}>
        WYBRANY: {prop(value, `translations.${LANGS_IRI.PL}.${titleProperty}`)}
      </div>
      <TextField
        value={text}
        onChange={handleTextChange}
        onKeyUp={handleTextKeyUp}
        disabled={disabled}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label?.color && {
            style: {
              color: label.color,
            },
          }
        }
        placeholder="Wpisz, aby zobaczyć podpowiedzi i wybrać"
        variant="standard"
        style={{
          width: 400,
        }}
      />
      <div className={classes.choices}>
        {choices.map(choice => (
          <div uuid={choice.uuid} onClick={handleChoose} key={choice.uuid}>
            {choice.title}
          </div>
        ))}
      </div>
    </>
  )
}
