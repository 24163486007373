import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { constants } from 'pages/CollectionsThematic/components/choose_table/_state'
import { commonConstants } from '_constants'
import collectionSchema from '_schema/collection'

const execute = (
  request,
  requests,
  tableStateDispatch,
  dispatch,
  action,
  items
) => {
  fetchDataHandleAuthError(request.url, request.method, {
    body: JSON.stringify(request.body),
  }).then(() => {
    if (!requests.length) {
      notification('success', 'Obiekty dodane', 'Sukces')
      tableStateDispatch({
        type: constants.MASS_SELECT_ACTION_SUCCESS,
        payload: { action, items },
      })

      dispatch({ type: commonConstants.INIT_RELOAD })

      return
    }

    execute(
      requests.shift(),
      requests,
      tableStateDispatch,
      dispatch,
      action,
      items
    )
  })
}

const perform = (parent, dispatch, action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let requests = []

    const items = tableState.data.selected.map((isSelected, index) => {
      if (!isSelected) {
        return tableState.data.items[index]
      }

      requests.push({
        url: collectionSchema.subresources.exhibits.endpoint,
        method: 'POST',
        body: {
          parent,
          exhibit: tableState.data.items[index]['@id'],
          stat: true,
        },
      })

      return tableState.data.items[index]
    })

    requests.reverse()

    if (!requests.length) {
      return
    }

    setTimeout(() => {
      execute(
        requests.shift(),
        requests,
        tableStateDispatch,
        dispatch,
        action,
        items
      )
    }, 500)
  },
})

export const operations = {
  add: (parent, dispatch, action = 'add', title = 'Dodaj do kolekcji') =>
    perform(parent, dispatch, action, title),
}
