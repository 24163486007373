import _block from './_block'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/pages',
  clone_endpoint: '/api/pages/clone',
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
        },
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(mediaSchema.endpoint, '/api/page_block_thumbs').types,
    },
  },
}

export default schema
