import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { LANGS_IRI } from '_lib/langs'
import { useStyles } from 'components/resource/sidebar/Sidebar.styles'

export const Sidebar = ({
  resource,
  titleAccessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  statAccessor = 'stat',
  collectionPath,
  handleSyncUpdatedAt,
}) => {
  const defaultClasses = useStyles()

  const history = useHistory()

  const onDeleteSuccess = () => {
    history.push(collectionPath)
  }

  const profile = useSelector(state => state.profile)

  return (
    <List className={defaultClasses.list}>
      <ListItem className={defaultClasses.item}>
        <Status
          resource={resource}
          accessor={statAccessor}
          isSwitch={false}
          handleSyncUpdatedAt={handleSyncUpdatedAt}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Delete
          resource={resource}
          accessor={titleAccessor}
          disabled={!profile.group?.isOpenform && !!resource.idName}
          onSuccess={onDeleteSuccess}
        />
      </ListItem>
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  handleSyncUpdatedAt: PropTypes.func.isRequired,
}
