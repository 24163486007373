export const addOpenformProperties = (isOpenform, properties) => {
  const modifiedProperties = properties

  if (isOpenform) {
    return {
      ...properties,
      idName: {
        type: 'string',
        description: {
          text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
          color: '#cc0000',
        },
      },
    }
  }

  return modifiedProperties
}
