import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'pages/DictionaryBooks/table/_columns/operations'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/dictionaryTechnique'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Tytuł',
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
      },
      {
        ...operations(),
        width: '13%',
      },
    ],
    []
  )

  return (
    <Paper>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'translations.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        isRowLinkable={true}
        editPath={routes().view.path}
        autoWidth={false}
        perPage={50}
      />
    </Paper>
  )
}
