import {
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  MenuBookOutlined,
} from '@material-ui/icons'
import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import dictionaryBooks from 'pages/DictionaryBooks/routes'
import dictionaryRoles from 'pages/DictionaryRoles/routes'
import dictionaryDivisions from 'pages/DictionaryDivisions/routes'
import dictionaryLocations from 'pages/DictionaryLocations/routes'
import dictionaryPlaces from 'pages/DictionaryPlaces/routes'
import dictionaryTechniques from 'pages/DictionaryTechniques/routes'
import dictionaryMaterials from 'pages/DictionaryMaterials/routes'
import dictionaryTypes from 'pages/DictionaryTypes/routes'
import dictionaryKeywords from 'pages/DictionaryKeywords/routes'
import dictionaryPublications from 'pages/DictionaryPublications/routes'
import dictionaryExhibitions from 'pages/DictionaryExhibitions/routes'
import creators from 'pages/Creators/routes'
import exhibits from 'pages/Exhibits/routes'
import collectionsAutomatic from 'pages/CollectionsAutomatic/routes'
import collectionsThematic from 'pages/CollectionsThematic/routes'
import importEntries from 'pages/ImportEntries/routes'
import config from 'pages/Config/routes'

const menu = () => [
  home().index,
  pages().index,
  creators().index,
  exhibits().index,
  collectionsAutomatic().index,
  collectionsThematic().index,
  {
    title: 'Dictionaries',
    icon: MenuBookOutlined,
    items: [
      dictionaryBooks().index,
      dictionaryRoles().index,
      dictionaryDivisions().index,
      dictionaryLocations().index,
      dictionaryPlaces().index,
      dictionaryTechniques().index,
      dictionaryMaterials().index,
      dictionaryTypes().index,
      dictionaryKeywords().index,
      dictionaryPublications().index,
      dictionaryExhibitions().index,
    ],
  },
  {
    title: 'Administrators',
    icon: PeopleOutlined,
    items: [admins().index, adminGroups().index],
  },
  {
    title: 'T_MENU_REGISTRIES',
    icon: DescriptionOutlined,
    items: [registryEntries().index, loginEntries().index],
  },
  {
    title: 'T_MODULE_SETTINGS',
    icon: SettingsOutlined,
    items: [settings().index, config().edit, profile().index],
  },
  importEntries().index,
  translatorEntries().index,
]

export default menu
