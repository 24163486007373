import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.SET_PAGE:
      return {
        ...state,
        config: {
          ...state.config,
          page: action.payload.page,
        },
      }
    case constants.SET_PER_PAGE:
      return {
        ...state,
        config: {
          ...state.config,
          page: 1,
          perPage: action.payload.perPage,
        },
      }
    case constants.RESET:
      return {
        ...state,
        config: {
          ...state.defaultConfig,
        },
      }
    case constants.SET_FILTERS:
      return {
        ...state,
        config: {
          ...state.config,
          rawFilters: action.payload.rawFilters,
          filters: Object.assign(
            {},
            ...Object.keys(action.payload.filters).map(name => ({
              [name]: {
                ...state.config.filters[name],
                value: action.payload.filters[name],
              },
            }))
          ),
        },
      }
    case constants.SET_SINGLE_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) =>
            i === action.payload.row ? !item : item
          ),
        },
      }
    case constants.SET_MASS_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((isSelected, i) =>
            (typeof action.payload.isRowSelectable === 'boolean'
            ? action.payload.isRowSelectable
            : action.payload.isRowSelectable(state.data.items[i]))
              ? !state.data.selectAll
              : isSelected
          ),
          selectAll: !state.data.selectAll,
        },
      }
    case constants.RELOAD:
      return {
        ...state,
        config: {
          ...state.config,
        },
      }
    case constants.FETCH_START:
    case constants.MASS_SELECT_ACTION_START:
      return {
        ...state,
        isFetching: true,
      }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        init: false,
        isFetching: false,
      }
    case constants.MASS_SELECT_ACTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: action.payload.items,
          selected: new Array(action.payload.items.length).fill(false),
          selectAll: false,
        },
        isFetching: false,
      }
    case constants.MASS_SELECT_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
        isFetching: false,
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
