import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import dictionaryBooks from 'pages/DictionaryBooks/routes'
import dictionaryRoles from 'pages/DictionaryRoles/routes'
import dictionaryDivisions from 'pages/DictionaryDivisions/routes'
import dictionaryLocations from 'pages/DictionaryLocations/routes'
import dictionaryPlaces from 'pages/DictionaryPlaces/routes'
import dictionaryTechniques from 'pages/DictionaryTechniques/routes'
import dictionaryMaterials from 'pages/DictionaryMaterials/routes'
import dictionaryTypes from 'pages/DictionaryTypes/routes'
import dictionaryKeywords from 'pages/DictionaryKeywords/routes'
import dictionaryPublications from 'pages/DictionaryPublications/routes'
import dictionaryExhibitions from 'pages/DictionaryExhibitions/routes'
import creators from 'pages/Creators/routes'
import exhibits from 'pages/Exhibits/routes'
import collectionsAutomatic from 'pages/CollectionsAutomatic/routes'
import collectionsThematic from 'pages/CollectionsThematic/routes'
import importEntries from 'pages/ImportEntries/routes'
import config from 'pages/Config/routes'

const routes = () => [
  ...getArray(home()),
  ...getArray(pages()),
  ...getArray(admins()),
  ...getArray(adminGroups()),
  ...getArray(loginEntries()),
  ...getArray(registryEntries()),
  ...getArray(translatorEntries()),
  ...getArray(dictionaryBooks()),
  ...getArray(dictionaryRoles()),
  ...getArray(dictionaryDivisions()),
  ...getArray(dictionaryLocations()),
  ...getArray(dictionaryPlaces()),
  ...getArray(dictionaryTechniques()),
  ...getArray(dictionaryMaterials()),
  ...getArray(dictionaryTypes()),
  ...getArray(dictionaryKeywords()),
  ...getArray(dictionaryPublications()),
  ...getArray(dictionaryExhibitions()),
  ...getArray(creators()),
  ...getArray(exhibits()),
  ...getArray(collectionsAutomatic()),
  ...getArray(collectionsThematic()),
  ...getArray(importEntries()),
  ...getArray(settings()),
  ...getArray(profile()),
  ...getArray(config()),
]

const getArray = routes => Object.values(routes)

export default routes
