const schema = {
  endpoint: '/api/creators',
  access: 'creator',
  resource: {
    definition: '#Creator',
  },
  subresources: {
    nationalities: {
      endpoint: '/api/creator_nationalities',
    },
    roles: {
      endpoint: '/api/creator_roles',
    },
    places: {
      endpoint: '/api/creator_places',
    },
    bibliographies: {
      endpoint: '/api/creator_bibliographies',
    },
    sources: {
      endpoint: '/api/creator_sources',
    },
    keywords: {
      endpoint: '/api/creator_keywords',
    },
    multimedia: {
      endpoint: '/api/creator_multimedia',
    },
    alternations: {
      endpoint: '/api/creator_alternations',
    },
    storeLinks: {
      endpoint: '/api/creator_store_links',
      definition: '#CreatorStoreLink',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            link: {},
            alt: {},
          },
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: '/api/media',
        },
      },
    },
  },
}

export default schema
