import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'pages'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { EmbeddedCollection } from 'components/embedded'
import { ChooseTable } from './components/choose_table/ChooseTable'
import { operations as selectableOperations } from 'pages/CollectionsThematic/components/_mass/delete'
import { operations } from 'components/table/_columns/operations'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/collection'
import exhibitSchema from '_schema/exhibit'
import routes from './routes'
import exhibitRoutes from 'pages/Exhibits/routes'

const useStyles = makeStyles({
  image_header: {
    fontSize: 20,
    marginBottom: 10,
  },
  exhibit_header: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  hint: {
    fontSize: 12,
    color: 'grey',
    marginBottom: 20,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const exhibitCollectionColumns = useMemo(
    () => [
      {
        header: 'Tytuł',
        accessor: `exhibit.translations.${LANGS_IRI.PL}.title`,
      },
      {
        header: 'ID',
        accessor: 'exhibit.museumId',
      },
      {
        ...operations(`exhibit.translations.${LANGS_IRI.PL}.title`, false),
        width: '13%',
      },
    ],
    []
  )

  const classes = useStyles()

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties => {
        delete properties.idName

        return properties
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`}
      fieldsFullWidth={false}
      width={600}
    >
      <div className={classes.image_header}>Pliki pdf</div>
      <EmbeddedCollection
        endpoint={schema.subresources.files.endpoint}
        parentIri={iri}
        properties={schema.subresources.files.properties}
        definitionSchema={definitions[schema.subresources.files.definition]}
        titleAccessor={resource =>
          resource.translations[LANGS_IRI.PL].media?.originalName ||
          'brak pliku'
        }
        statable={true}
      />
      <div className={classes.exhibit_header}>Multimedia</div>
      <EmbeddedCollection
        endpoint={schema.subresources.multimedia.endpoint}
        parentIri={iri}
        properties={schema.subresources.multimedia.properties}
        definitionSchema={
          definitions[schema.subresources.multimedia.definition]
        }
        statable={false}
      />
      <div className={classes.exhibit_header}>Zdjęcie</div>
      <div className={classes.hint}>
        {
          'Dla etnografii wyświetli 4 pierwsze opublikowane zdjęcia a dla pozostałych tylko 1 zdjęcie'
        }
      </div>
      <EmbeddedCollection
        endpoint={schema.subresources.images.endpoint}
        parentIri={iri}
        properties={schema.subresources.images.properties}
        definitionSchema={definitions[schema.subresources.images.definition]}
        titleAccessor={resource =>
          resource.media?.originalName || 'brak zdjęcia'
        }
        statable={true}
      />
      <div className={classes.exhibit_header}>Dodaj obiekty do kolekcji</div>
      <ChooseTable
        parent={iri}
        endpoint={exhibitSchema.endpoint}
        viewPath={exhibitRoutes().view.path}
      />
      <div className={classes.exhibit_header}>Obiekty dodane</div>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.subresources.exhibits.definition}
        endpoint={schema.subresources.exhibits.endpoint}
        columns={exhibitCollectionColumns}
        customResourceSchema={schema.resource}
        defaultFilters={{ 'iri[parent]': iri }}
        defaultSorters={{ ord: 'desc' }}
        storeCollectionId={`collection_exhibits_${iri}`}
        selectable={[selectableOperations.delete()]}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={true}
        isRowLinkable={true}
        editPath={exhibitRoutes().view.path}
        autoWidth={false}
      />
    </Form>
  )
}
