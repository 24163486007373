import React from 'react'
import { useHistory } from 'react-router-dom'
import { LANGS_IRI } from '_lib/langs'

export const ExhibitCell = ({ resource, viewPath }) => {
  const history = useHistory()

  const onClick = () => {
    history.push(viewPath.replace(':id', resource.uuid))
  }

  return (
    <div onClick={onClick}>
      {resource.translations[LANGS_IRI.PL].title} ({resource.museumId})
    </div>
  )
}
