import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const text = () => ({
  name: BLOCK_TYPES_IRI.TEXT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
      },
    },
  },
})
