const schema = {
  endpoint: '/api/exhibits',
  access: 'exhibit',
  resource: {
    definition: '#Exhibit',
  },
  subresources: {
    creators: {
      endpoint: '/api/exhibit_creators',
    },
    materials: {
      endpoint: '/api/exhibit_materials',
    },
    techniques: {
      endpoint: '/api/exhibit_techniques',
    },
    places: {
      endpoint: '/api/exhibit_places',
    },
    types: {
      endpoint: '/api/exhibit_types',
    },
    keywords: {
      endpoint: '/api/exhibit_keywords',
    },
    exhibitions: {
      endpoint: '/api/exhibit_exhibitions',
    },
    bibliographies: {
      endpoint: '/api/exhibit_bibliographies',
    },
    multimedia: {
      endpoint: '/api/exhibit_multimedia',
    },
    images: {
      endpoint: '/api/exhibit_images',
    },
    histories: {
      endpoint: '/api/exhibit_histories',
    },
    related: {
      endpoint: '/api/exhibit_relateds',
    },
    depictedPersons: {
      endpoint: '/api/exhibit_depicted_people',
    },
    owners: {
      endpoint: '/api/exhibit_owners',
    },
    additionalDescriptions: {
      endpoint: '/api/exhibit_additional_descriptions',
    },
  },
}

export default schema
