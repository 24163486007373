import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  TextField,
} from '@material-ui/core'
import { AutocompleteTreeFilter } from 'pages/CollectionsThematic/components/choose_table/AutocompleteTreeFilter'
import { AutocompleteFilter } from 'pages/CollectionsThematic/components/choose_table/AutocompleteFilter'
import { SelectFilter } from './SelectFilter'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { LANGS_IRI } from '_lib/langs'
import typeSchema from '_schema/dictionaryType'
import materialSchema from '_schema/dictionaryMaterial'
import techniqueSchema from '_schema/dictionaryTechnique'
import placeSchema from '_schema/dictionaryPlace'
import keywordSchema from '_schema/dictionaryKeyword'
import creatorSchema from '_schema/creator'
import divisionSchema from '_schema/dictionaryDivision'
import exhibitionSchema from '_schema/dictionaryExhibition'

const useStyles = makeStyles(theme => ({
  filters: {
    // display: 'flex',
    '&>*': {
      marginBottom: 8,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    alignItems: 'center',
  },
  confirm_button: {
    color: theme.palette.success.main,
  },
  reset: {
    marginBottom: 0,
  },
  filter_header: {
    marginBottom: 10,
    '&>*': {
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 5,
    },
    '&>*:first-child': {
      marginLeft: 0,
    },
  },
  filter_header_years: {
    display: 'flex',
    alignItems: 'center',
    '&> *': {
      marginLeft: 5,
      marginRight: 5,
    },
    marginTop: 15,
  },
  text_field: {
    width: 100,
  },
}))

export const FilterBar = ({ resource }) => {
  const [state, setState] = useState({
    creator: {
      name: 'creators',
      value: resource.creators || [],
    },
    type: {
      name: 'types',
      value: resource.types || [],
    },
    material: {
      name: 'materials',
      value: resource.materials || [],
    },
    technique: {
      name: 'techniques',
      value: resource.techniques || [],
    },
    place: {
      name: 'places',
      value: resource.places || [],
    },
    keyword: {
      name: 'keywords',
      value: resource.keywords || [],
    },
    yearFrom: {
      name: 'yearFrom',
      value: resource.yearFrom,
    },
    yearTo: {
      name: 'yearTo',
      value: resource.yearTo,
    },
    division: {
      name: 'divisions',
      value: resource.divisions || [],
    },
    exhibition: {
      name: 'exhibitions',
      value: resource.exhibitions || [],
    },
    museumId: {
      name: 'museumId',
      value: resource.museumId,
    },
  })

  const [choices, setChoices] = useState({})

  const setTextForName = name => e => {
    const value = e.target.value
    setState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    }))
  }

  const setNumberForName = name => e => {
    const value = e.target.value
    setState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value: parseInt(value),
      },
    }))
  }

  const setSelectedForName = (name, iri, title) => {
    setState(state => {
      const local = { ...state }
      const found = local[name]?.value.find(element => element['@id'] === iri)

      if (found) {
        local[name] = {
          ...local[name],
          value: local[name].value.filter(element => element['@id'] !== iri),
        }
      } else {
        local[name] = {
          ...local[name],
          value: [
            ...local[name].value,
            {
              '@id': iri,
              translations: {
                [LANGS_IRI.PL]: {
                  title,
                },
              },
            },
          ],
        }
      }

      return local
    })
  }

  const setMultipleSelectedForName = (name, selected) => {
    setState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value: selected.map(s => ({
          '@id': s.value,
          translations: {
            [LANGS_IRI.PL]: {
              title: s.title,
            },
          },
        })),
      },
    }))
  }

  const setChoicesForName = (name, id, choices, type) => {
    setChoices(state => ({
      ...state,
      [name]: {
        ...state[name],
        [id]:
          type === 'append'
            ? [...(state[name]?.[id] ?? []), ...choices]
            : choices,
      },
    }))
  }

  const handleConfirm = () => {
    fetchDataHandleAuthError(
      resource['@id'],
      'PUT',
      {
        body: JSON.stringify(
          Object.assign(
            {},
            ...Object.keys(state).map(property => ({
              [state[property].name]: state[property].value,
            }))
          )
        ),
      },
      () => {
        notification('success', 'Zapisano', 'Sukces')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <div>
      <div className={classes.filters}>
        <div>
          <div className={classes.filter_header}>
            <span>Twórcy:</span>
            {state.creator?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'creator',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteFilter
                name="creator"
                autocompleteEndpoint="/api-front/creators/autocomplete_raw"
                iriTemplate={`${creatorSchema.endpoint}/:uuid`}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Rodzaje:</span>
            {state.type?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'type',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="type"
                endpoint={typeSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_types/autocomplete"
                iriTemplate={`${typeSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Tworzywa:</span>
            {state.material?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'material',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="material"
                endpoint={materialSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_materials/autocomplete"
                iriTemplate={`${materialSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Techniki:</span>
            {state.technique?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'technique',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="technique"
                endpoint={techniqueSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_techniques/autocomplete"
                iriTemplate={`${techniqueSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Miejsca:</span>
            {state.place?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'place',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="place"
                endpoint={placeSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_places/autocomplete/exhibits"
                iriTemplate={`${placeSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Słowa kluczowe:</span>
            {state.keyword?.value.map(element => (
              <Chip
                key={element['@id']}
                onDelete={() =>
                  setSelectedForName(
                    'keyword',
                    element['@id'],
                    element.translations[LANGS_IRI.PL].title
                  )
                }
                label={element.translations[LANGS_IRI.PL].title}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteFilter
                name="keyword"
                autocompleteEndpoint="/api-front/dictionary_keywords/autocomplete_raw/exhibits"
                iriTemplate={`${keywordSchema.endpoint}/:uuid`}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.filters}>
          <div>
            <div className={classes.filter_header}>
              <span>Działy:</span>
              {state.division?.value.map(element => (
                <Chip
                  key={element['@id']}
                  onDelete={() =>
                    setSelectedForName(
                      'division',
                      element['@id'],
                      element.translations[LANGS_IRI.PL].title
                    )
                  }
                  label={element.translations[LANGS_IRI.PL].title}
                />
              ))}
            </div>
            <Accordion>
              <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
              <AccordionDetails>
                <SelectFilter
                  name="division"
                  endpoint={`${divisionSchema.endpoint}?pagination=false&empty[exhibitDivisions]=false&order[ord]=asc`}
                  selected={state.division.value}
                  setSelected={setMultipleSelectedForName}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={classes.filters}>
          <div>
            <div className={classes.filter_header}>
              <span>Wystawy:</span>
              {state.exhibition?.value.map(element => (
                <Chip
                  key={element['@id']}
                  onDelete={() =>
                    setSelectedForName(
                      'exhibition',
                      element['@id'],
                      element.translations[LANGS_IRI.PL].title
                    )
                  }
                  label={element.translations[LANGS_IRI.PL].title}
                />
              ))}
            </div>
            <Accordion>
              <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
              <AccordionDetails>
                <SelectFilter
                  name="exhibition"
                  endpoint={`${exhibitionSchema.endpoint}?pagination=false&empty[exhibitExhibitions]=false&order[ord]=asc`}
                  selected={state.exhibition.value}
                  setSelected={setMultipleSelectedForName}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className={classes.filters}>
        <div>
          <div className={classes.filter_header_years}>
            <span>Datowanie od:</span>
            <TextField
              type="number"
              value={state.yearFrom?.value || ''}
              onChange={setNumberForName('yearFrom')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
            <span>Datowanie do:</span>
            <TextField
              type="number"
              value={state.yearTo?.value || ''}
              onChange={setNumberForName('yearTo')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
            <span>Numer inwentarzowy:</span>
            <TextField
              type="museumId"
              value={state.museumId?.value || ''}
              onChange={setTextForName('museumId')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={handleConfirm}
          className={classes.confirm_button}
          variant="contained"
        >
          Zapisz
        </Button>
      </div>
    </div>
  )
}
