import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { ResultCell } from 'pages/LoginEntries/table/cells/ResultCell'
import { LogFileCell } from './table/cells/LogFileCell'
import { MessageCell } from './table/cells/MessageCell'
import { translate } from '_helpers/translate'
import schema from '_schema/importEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('message'),
        width: '70%',
        Cell: MessageCell
      },
      {
        ...standard('success'),
        width: '10%',
        Cell: ResultCell,
      },
      {
        header: 'Data',
        ...standard('createdAt'),
        width: '13%',
        Cell: LogFileCell
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
