import React, { useState } from 'react'
import { ExpandMoreOutlined, ChevronRightOutlined } from '@material-ui/icons'
import { TreeView, TreeItem } from '@material-ui/lab'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { LANGS_IRI } from '_lib/langs'
import buildUrl from 'build-url'

const PER_PAGE = 100

const fetchTree = (
  name,
  endpoint,
  page,
  parentHierarchy,
  signal,
  setChoices
) => {
  const url = buildUrl(endpoint, {
    queryParams: {
      isUsed: true,
      page,
      perPage: PER_PAGE,
      ...(parentHierarchy
        ? { parentHierarchy }
        : { 'exists[parentHierarchy]': false }),
    },
  })

  fetchDataHandleAuthError(
    url,
    'GET',
    { signal },
    response => {
      setChoices(name, parentHierarchy, response['hydra:member'], 'append')

      const max = Math.ceil(response['hydra:totalItems'] / PER_PAGE)

      if (page >= max) {
        return
      }

      fetchTree(name, endpoint, page + 1, parentHierarchy, signal, setChoices)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
    }
  )
}

export const TreeFilter = ({
  name,
  endpoint,
  parentHierarchy = null,
  choices,
  setChoices,
  setSelected,
}) => {
  useState(() => {
    if (choices[name]?.[parentHierarchy] !== undefined) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchTree(name, endpoint, 1, parentHierarchy, signal, setChoices)
  }, [choices])

  return !parentHierarchy ? (
    <TreeView
      defaultCollapseIcon={<ExpandMoreOutlined />}
      defaultExpandIcon={<ChevronRightOutlined />}
    >
      {choices[name]?.[parentHierarchy]?.map(record => (
        <TreeItem
          nodeId={record['@id']}
          label={record.translations[LANGS_IRI.PL].title}
          key={record['@id']}
          onLabelClick={() =>
            setSelected(
              name,
              record['@id'],
              record.translations[LANGS_IRI.PL].title
            )
          }
        >
          {record.hasUsedChildren ? (
            <TreeFilter
              name={name}
              endpoint={endpoint}
              parentHierarchy={record.hierarchy}
              choices={choices}
              setChoices={setChoices}
              setSelected={setSelected}
            />
          ) : null}
        </TreeItem>
      ))}
    </TreeView>
  ) : (
    choices[name]?.[parentHierarchy]?.map(record => (
      <TreeItem
        nodeId={record['@id']}
        label={record.translations[LANGS_IRI.PL].title}
        key={record['@id']}
        onLabelClick={() =>
          setSelected(
            name,
            record['@id'],
            record.translations[LANGS_IRI.PL].title
          )
        }
      >
        {record.hasUsedChildren ? (
          <TreeFilter
            name={name}
            endpoint={endpoint}
            parentHierarchy={record.hierarchy}
            choices={choices}
            setChoices={setChoices}
            setSelected={setSelected}
          />
        ) : null}
      </TreeItem>
    )) ?? ''
  )
}
