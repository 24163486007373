import React, { useCallback, useState } from 'react'
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import { ViewCollection } from 'pages/Exhibits/collection/ViewCollection'
import schema from '_schema/dictionaryMaterial'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  margin_top: {
    marginTop: 20,
  },
  accordion_wrapper: {
    width: '100%',
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <h2>{resource.translations[LANGS_IRI.PL].title}</h2>
        <h4>MONA ID: {resource.importId}</h4>
        <h5>Miejsce w hierarchii: {resource.hierarchy}</h5>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].title}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].description}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].title}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].description}
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="alternations-content"
            id="alternations-header"
          >
            Oboczności
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.alternations.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
}
