import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  TextField,
} from '@material-ui/core'
import { TableReset } from 'components/table'
import { AutocompleteTreeFilter } from './AutocompleteTreeFilter'
import { AutocompleteFilter } from './AutocompleteFilter'
import { SelectFilter } from './SelectFilter'
import { translate } from '_helpers/translate'
import typeSchema from '_schema/dictionaryType'
import materialSchema from '_schema/dictionaryMaterial'
import techniqueSchema from '_schema/dictionaryTechnique'
import placeSchema from '_schema/dictionaryPlace'
import keywordSchema from '_schema/dictionaryKeyword'
import creatorSchema from '_schema/creator'
import divisionSchema from '_schema/dictionaryDivision'
import exhibitionSchema from '_schema/dictionaryExhibition'

const useStyles = makeStyles(theme => ({
  filters: {
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    alignItems: 'center',
  },
  confirm_button: {
    color: theme.palette.success.main,
  },
  reset: {
    marginBottom: 0,
  },
  filter_header: {
    marginBottom: 10,
    '&>*': {
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 5,
    },
    '&>*:first-child': {
      marginLeft: 0,
    },
  },
  filter_header_years: {
    display: 'flex',
    alignItems: 'center',
    '&> *': {
      marginLeft: 5,
      marginRight: 5,
    },
    marginTop: 15,
  },
  text_field: {
    width: 100,
  },
}))

export const FilterBar = ({
  rawFilters,
  setFilters,
  handleReset,
  disabled,
}) => {
  const [state, setState] = useState(rawFilters)
  const [choices, setChoices] = useState({})

  const setTextForName = name => e => {
    const value = e.target.value
    setState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    }))
  }

  const setSelectedForName = (name, uuid, title) => {
    setState(state => {
      const local = { ...state }

      if (local[name]?.value?.[uuid]) {
        delete local[name].value[uuid]
      } else {
        local[name] = {
          ...local[name],
          value: {
            ...local[name].value,
            [uuid]: title,
          },
        }
      }

      return local
    })
  }

  const setMultipleSelectedForName = (name, selected) => {
    setState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value: Object.assign(
          {},
          ...selected.map(s => ({ [s.value]: s.title }))
        ),
      },
    }))
  }

  const setChoicesForName = (name, id, choices, type) => {
    setChoices(state => ({
      ...state,
      [name]: {
        ...state[name],
        [id]:
          type === 'append'
            ? [...(state[name]?.[id] ?? []), ...choices]
            : choices,
      },
    }))
  }

  const handleSearch = () => {
    if (disabled) {
      return
    }

    setFilters(
      state,
      Object.assign(
        {},
        ...Object.keys(state).map(name => ({
          [name]:
            typeof state[name]?.value === 'object' &&
            state[name]?.value !== null
              ? Object.keys(state[name].value)
              : state[name].value,
        }))
      )
    )
  }

  useEffect(() => {
    setState(rawFilters)
  }, [rawFilters, setState])

  const classes = useStyles()

  return (
    <div>
      <div className={classes.filters}>
        <div>
          <div className={classes.filter_header}>
            <span>Twórcy:</span>
            {Object.keys(state.creator?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName(
                    'creator',
                    uuid,
                    state.creator?.value[uuid]
                  )
                }
                label={state.creator?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteFilter
                name="creator"
                autocompleteEndpoint="/api-front/creators/autocomplete_raw"
                iriTemplate={`${creatorSchema.endpoint}/:uuid`}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Rodzaje:</span>
            {Object.keys(state.type?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName('type', uuid, state.type?.value[uuid])
                }
                label={state.type?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="type"
                endpoint={typeSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_types/autocomplete"
                iriTemplate={`${typeSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Tworzywa:</span>
            {Object.keys(state.material?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName(
                    'material',
                    uuid,
                    state.material?.value[uuid]
                  )
                }
                label={state.material?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="material"
                endpoint={materialSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_materials/autocomplete"
                iriTemplate={`${materialSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Techniki:</span>
            {Object.keys(state.technique?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName(
                    'technique',
                    uuid,
                    state.technique?.value[uuid]
                  )
                }
                label={state.technique?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="technique"
                endpoint={techniqueSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_techniques/autocomplete"
                iriTemplate={`${techniqueSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Miejsca:</span>
            {Object.keys(state.place?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName('place', uuid, state.place?.value[uuid])
                }
                label={state.place?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteTreeFilter
                name="place"
                endpoint={placeSchema.endpoint}
                autocompleteEndpoint="/api-front/dictionary_places/autocomplete/exhibits"
                iriTemplate={`${placeSchema.endpoint}/:uuid`}
                parentHierarchy={null}
                choices={choices}
                setChoices={setChoicesForName}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div className={classes.filter_header}>
            <span>Słowa kluczowe:</span>
            {Object.keys(state.keyword?.value || {}).map(uuid => (
              <Chip
                key={uuid}
                onDelete={() =>
                  setSelectedForName(
                    'keyword',
                    uuid,
                    state.keyword?.value[uuid]
                  )
                }
                label={state.keyword?.value[uuid]}
              />
            ))}
          </div>
          <Accordion>
            <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
            <AccordionDetails>
              <AutocompleteFilter
                name="keyword"
                autocompleteEndpoint="/api-front/dictionary_keywords/autocomplete_raw/exhibits"
                iriTemplate={`${keywordSchema.endpoint}/:uuid`}
                setSelected={setSelectedForName}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.filters}>
          <div>
            <div className={classes.filter_header}>
              <span>Działy:</span>
              {Object.keys(state.division?.value || {}).map(uuid => (
                <Chip
                  key={uuid}
                  onDelete={() =>
                    setSelectedForName(
                      'division',
                      uuid,
                      state.division?.value[uuid]
                    )
                  }
                  label={state.division?.value[uuid]}
                />
              ))}
            </div>
            <Accordion>
              <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
              <AccordionDetails>
                <SelectFilter
                  name="division"
                  endpoint={`${divisionSchema.endpoint}?pagination=false&empty[exhibitDivisions]=false&order[ord]=asc`}
                  selected={state.division.value}
                  setSelected={setMultipleSelectedForName}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={classes.filters}>
          <div>
            <div className={classes.filter_header}>
              <span>Wystawy:</span>
              {Object.keys(state.exhibition?.value || {}).map(uuid => (
                <Chip
                  key={uuid}
                  onDelete={() =>
                    setSelectedForName(
                      'exhibition',
                      uuid,
                      state.exhibition?.value[uuid]
                    )
                  }
                  label={state.exhibition?.value[uuid]}
                />
              ))}
            </div>
            <Accordion>
              <AccordionSummary>Rozwiń/zwiń</AccordionSummary>
              <AccordionDetails>
                <SelectFilter
                  name="exhibition"
                  endpoint={`${exhibitionSchema.endpoint}?pagination=false&empty[exhibitExhibitions]=false&order[ord]=asc`}
                  selected={state.exhibition.value}
                  setSelected={setMultipleSelectedForName}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className={classes.filters}>
        <div>
          <div className={classes.filter_header_years}>
            <span>Datowanie od:</span>
            <TextField
              type="number"
              value={state.yearFrom?.value || ''}
              onChange={setTextForName('yearFrom')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
            <span>Datowanie do:</span>
            <TextField
              type="number"
              value={state.yearTo?.value || ''}
              onChange={setTextForName('yearTo')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
            <span>Numer inwentarzowy:</span>
            <TextField
              type="museumId"
              value={state.museumId?.value || ''}
              onChange={setTextForName('museumId')}
              variant="outlined"
              classes={{
                root: classes.text_field,
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={handleSearch}
          className={classes.confirm_button}
          variant="contained"
          disabled={disabled}
        >
          {translate('T_GENERAL_SEARCH')}
        </Button>
        <TableReset
          disabled={disabled}
          handleReset={handleReset}
          classes={{ icon: classes.reset }}
        />
      </div>
    </div>
  )
}
