const schema = {
  endpoint: '/api/dictionary_techniques',
  access: 'dictionaryTechnique',
  resource: {
    definition: '#DictionaryTechnique',
  },
  subresources: {
    alternations: {
      endpoint: '/api/dictionary_technique_alternations',
    },
  },
}

export default schema
