const schema = {
  endpoint: '/api/dictionary_places',
  access: 'dictionaryPlace',
  resource: {
    definition: '#DictionaryPlace',
  },
  subresources: {
    alternations: {
      endpoint: '/api/dictionary_place_alternations',
    },
  },
}

export default schema
