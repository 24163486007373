export const constants = {
  SET_PAGE: 'CHOOSE_TABLE_SET_PAGE',
  SET_PER_PAGE: 'CHOOSE_TABLE_SET_PER_PAGE',
  SET_SINGLE_SELECT: 'CHOOSE_TABLE_SET_SINGLE_SELECT',
  SET_MASS_SELECT: 'CHOOSE_TABLE_SET_MASS_SELECT',
  SET_FILTERS: 'CHOOSE_TABLE_SET_FILTERS',
  RESET: 'CHOOSE_TABLE_RESET',
  RELOAD: 'CHOOSE_TABLE_RELOAD',
  FETCH_START: 'CHOOSE_TABLE_FETCH_START',
  FETCH_SUCCESS: 'CHOOSE_TABLE_FETCH_SUCCESS',
  FETCH_FAILURE: 'CHOOSE_TABLE_FETCH_FAILURE',
  MASS_SELECT_ACTION_START: 'CHOOSE_TABLE_MASS_SELECT_ACTION_START',
  MASS_SELECT_ACTION_SUCCESS: 'CHOOSE_TABLE_MASS_SELECT_ACTION_SUCCESS',
  MASS_SELECT_ACTION_FAILURE: 'CHOOSE_TABLE_MASS_SELECT_ACTION_FAILURE',
}
