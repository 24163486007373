import { LANGS_IRI } from '_lib/langs'

const schema = {
  endpoint: '/api/collections',
  access: 'collection',
  resource: {
    definition: '#Collection',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'textarea',
          },
          link: {
            description: 'Link do muzealnego sklepu',
          },
          // media: {
          //   type: 'file',
          //   description: 'Plik pdf',
          //   endpoint: '/api/media',
          //   accept: 'application/pdf'
          // },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/kolekcja/:slug',
              [LANGS_IRI.EN]: '/collection/slug',
            },
          },
        },
      },
      mainExhibit: {
        type: 'autocomplete',
        endpoint: '/api-front/exhibits/autocomplete_raw',
        iriTemplate: '/api/exhibits/:uuid',
        titleProperty: 'uniqueTitle',
      },
      isHomeWider: {},
      isMasterpiece: {},
    },
  },
  subresources: {
    exhibits: {
      endpoint: '/api/collection_exhibits',
      definition: '#CollectionExhibit',
      properties: {
        exhibit: {
          type: 'resource',
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,
        },
      },
    },
    images: {
      endpoint: '/api/collection_images',
      definition: '#CollectionImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            alt: {},
          },
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: '/api/media',
        },
      },
    },
    files: {
      endpoint: '/api/collection_files',
      definition: '#CollectionFile',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            media: {
              type: 'file',
              description: 'Plik pdf',
              accept: 'application/pdf',
              endpoint: '/api/media',
            },
          },
        },
      },
    },
    multimedia: {
      endpoint: '/api/collection_multimedia',
      definition: '#CollectionMultimedia',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        link: {},
      },
    },
  },
}

export default schema
