const schema = {
  endpoint: '/api/dictionary_types',
  access: 'dictionaryType',
  resource: {
    definition: '#DictionaryType',
  },
  subresources: {
    alternations: {
      endpoint: '/api/dictionary_type_alternations',
    },
  },
}

export default schema
