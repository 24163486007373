import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { TreeFilter } from './TreeFilter'
import buildUrl from 'build-url'

const useStyles = makeStyles({
  header: {
    marginBottom: 20,
  },
  chosen: {
    color: 'gray',
    marginBottom: 10,
  },
  choices: {
    marginTop: 10,
    marginBottom: 10,
    '&>*:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

export const AutocompleteTreeFilter = ({
  name,
  endpoint,
  iriTemplate,
  autocompleteEndpoint,
  choices,
  setChoices,
  setSelected,
}) => {
  const [autocompleteText, setAutocompleteText] = useState('')
  const [autocompleteChoices, setAutocompleteChoices] = useState([])

  const handleAutocompleteTextChange = e => {
    const value = e.target.value
    setAutocompleteText(value)
  }

  const handleAutocompleteTextKeyUp = e => {
    const value = e.target.value

    if (value.length < 3) {
      setAutocompleteChoices([])

      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(autocompleteEndpoint, {
      queryParams: { text: autocompleteText },
    })

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setAutocompleteChoices(response['autocomplete_results'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleAutocompleteChoose = e => {
    const uuid = e.target.getAttribute('uuid')
    const title = e.target.innerText

    setSelected(name, iriTemplate.replace(':uuid', uuid), title)

    setAutocompleteText('')
    setAutocompleteChoices([])
  }

  const classes = useStyles()

  return (
    <div>
      <div clases={classes.header}>
        Wpisz i wybierz z podpowiedzi lub z hierarchi poniżej
      </div>
      <TextField
        value={autocompleteText}
        onChange={handleAutocompleteTextChange}
        onKeyUp={handleAutocompleteTextKeyUp}
        placeholder="Wpisz, aby zobaczyć podpowiedzi i wybrać"
        variant="standard"
        style={{
          width: 300,
          marginBottom: 5,
        }}
      />
      <div className={classes.choices}>
        {autocompleteChoices.map(choice => (
          <div
            uuid={choice.uuid}
            onClick={handleAutocompleteChoose}
            key={choice.uuid}
          >
            {choice.title}
          </div>
        ))}
      </div>
      <TreeFilter
        name={name}
        endpoint={endpoint}
        choices={choices}
        setChoices={setChoices}
        setSelected={setSelected}
      />
    </div>
  )
}
