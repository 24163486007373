import React, { useState, useEffect } from 'react'
import { TextField, FormControl, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { prop } from '_helpers/prop'
import { LANGS_IRI } from '_lib/langs'

const useStyles = makeStyles({
  form_control: {
    minWidth: 200,
  },
  autocomplete: {
    minWidth: 260,
  },
})

export const SelectFilter = ({ name, endpoint, selected, setSelected }) => {
  const [choices, setChoices] = useState([])

  const handleChange = (e, options) => {
    setSelected(name, options)
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, setChoices])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: prop(resource, `translations.${LANGS_IRI.PL}.title`),
  }))

  const classes = useStyles()

  return (
    <FormControl className={classes.form_control}>
      <Autocomplete
        name={name}
        options={options}
        multiple
        getOptionLabel={option => option.title}
        onChange={handleChange}
        value={Object.keys(selected).map(uuid => ({
          value: uuid,
          title: selected[uuid],
        }))}
        renderInput={params => <TextField variant="outlined" {...params} />}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        classes={{ root: classes.autocomplete }}
      />
    </FormControl>
  )
}
