import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { Form } from 'pages'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { Sidebar } from './sidebar'
import { EmbeddedCollection } from 'components/embedded'
import { FilterBar } from './components/FilterBar'
import { ViewCollection } from 'pages/Exhibits/collection/ViewCollection'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/collection'
import routes from './routes'

const useStyles = makeStyles({
  image_header: {
    fontSize: 20,
    marginBottom: 10,
  },
  exhibit_header: {
    marginTop: 20,
  },
  filter_header: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },
  hint: {
    fontSize: 12,
    color: 'grey',
    marginBottom: 20,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const profile = useSelector(state => state.profile)

  const classes = useStyles()

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties =>
        addOpenformProperties(profile.group?.isOpenform, properties)
      }
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`}
      SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      width={600}
    >
      {resource => (
        <>
          <div className={classes.image_header}>Pliki pdf</div>
          <EmbeddedCollection
            endpoint={schema.subresources.files.endpoint}
            parentIri={iri}
            properties={schema.subresources.files.properties}
            definitionSchema={definitions[schema.subresources.files.definition]}
            titleAccessor={resource =>
              resource.translations[LANGS_IRI.PL].media?.originalName ||
              'brak pliku'
            }
            statable={true}
          />
          <div className={classes.exhibit_header}>Zdjęcie</div>
          <div className={classes.hint}>
            {
              'Dla etnografii wyświetli 4 pierwsze opublikowane zdjęcia a dla pozostałych tylko 1 zdjęcie'
            }
          </div>
          <EmbeddedCollection
            endpoint={schema.subresources.images.endpoint}
            parentIri={iri}
            properties={schema.subresources.images.properties}
            definitionSchema={
              definitions[schema.subresources.images.definition]
            }
            titleAccessor={resource =>
              resource.media?.originalName || 'brak zdjęcia'
            }
            statable={true}
          />
          {!resource.idName && (
            <>
              <div className={classes.filter_header}>Wybierz filtry</div>
              <FilterBar resource={resource} />
            </>
          )}
          {resource.idName && (
            <div className={classes.exhibit_header}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="exhibits-content"
                  id="exhibits-header"
                >
                  Obiekty
                </AccordionSummary>
                <AccordionDetails>
                  <ViewCollection
                    endpoint={schema.subresources.exhibits.endpoint}
                    parentIri={iri}
                    render={resource => (
                      <div>
                        <Tooltip
                          title={`EN: ${
                            resource.exhibit.translations[LANGS_IRI.EN].title
                          }`}
                          className={classes.tooltip}
                          arrow
                        >
                          <span>
                            {resource.exhibit.translations[LANGS_IRI.PL].title}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </>
      )}
    </Form>
  )
}
