const schema = {
  endpoint: '/api/dictionary_keywords',
  access: 'dictionaryKeyword',
  resource: {
    definition: '#DictionaryKeyword',
  },
  subresources: {
    alternations: {
      endpoint: '/api/dictionary_keyword_alternations',
    },
  },
}

export default schema
