import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'

const execute = (request, requests, tableStateDispatch) => {
  fetchDataHandleAuthError(request.url, request.method, {}).then(() => {
    if (!requests.length) {
      notification('success', 'Obiekty usunięte', 'Sukces')

      tableStateDispatch({ type: constants.RELOAD })

      return
    }

    execute(requests.shift(), requests, tableStateDispatch)
  })
}

const perform = (action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let requests = []

    tableState.data.selected.map((isSelected, index) => {
      if (!isSelected) {
        return tableState.data.items[index]
      }

      requests.push({
        url: tableState.data.items[index]['@id'],
        method: 'DELETE',
      })

      return null
    })

    if (!requests.length) {
      return
    }

    setTimeout(() => {
      execute(requests.shift(), requests, tableStateDispatch)
    }, 500)
  },
})

export const operations = {
  delete: (action = 'delete', title = 'Usuń z kolekcji') =>
    perform(action, title),
}
