import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import { ViewCollection } from './collection/ViewCollection'
import { translate } from '_helpers/translate'
import schema from '_schema/exhibit'
import routes from './routes'
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  img: {
    maxWidth: 300,
    marginBottom: 8,
  },
  list: {
    marginTop: 20,
    '&>div': {
      marginTop: 5,
    },
  },
  margin_top: {
    marginTop: 20,
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
  accordion_wrapper: {
    width: '100%',
  },
  tooltip: {
    cursor: 'pointer',
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const link = `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/obiekt/${
    resource?.translations[LANGS_IRI.PL].slug
  }`

  const permalink = `${
    process.env.REACT_APP_BACKEND_ENTRYPOINT
  }/obiekt/p/${resource?.museumId
    .replace(' ', '_')
    .replace('/', '+')
    .toLowerCase()}`

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <h2>{resource.translations[LANGS_IRI.PL].title}</h2>
        <h4>MONA ID: {resource.importId}</h4>
        <div className={classes.data}>
          <div>
            <strong>ID muzealny:</strong> {resource.museumId}
          </div>
          <div>
            <strong>Prefix muzealny:</strong> {resource.museumPrefix}
          </div>
          <div>
            <strong>ID zespołu:</strong> {resource.museumUnitId}
          </div>
          {resource.museumUnitId && (
            <div>
              <strong>Kolejność w zespole:</strong> {resource.museumUnitOrd}
            </div>
          )}
          <div>
            <strong>Arcydzieło:</strong>{' '}
            {resource.isMasterpiece ? 'TAK' : 'NIE'}
          </div>
          <div>
            <strong>Lokalizacja:</strong>{' '}
            {resource.location?.translations[LANGS_IRI.PL].title}
          </div>
          <div>
            <strong>Dział:</strong>{' '}
            {resource.division?.translations[LANGS_IRI.PL].title}
          </div>
          <div>
            <strong>Rok od:</strong> {resource.yearFrom || ''}
          </div>
          <div>
            <strong>Rok do:</strong> {resource.yearTo || ''}
          </div>
          <div>
            <strong>Sygnowany:</strong> {resource.isSigned ? 'TAK' : 'NIE'}
          </div>
          <div>
            <strong>Link do audiodeskrypcji:</strong>{' '}
            {resource.audioDescriptionLink && (
              <a
                href={resource.audioDescriptionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resource.audioDescriptionLink}
              </a>
            )}
          </div>
          <div>
            <strong>Link do języka migowego:</strong>{' '}
            {resource.signLanguageLink && (
              <a
                href={resource.signLanguageLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resource.signLanguageLink}
              </a>
            )}
          </div>
        </div>
        <div className={classes.margin_top}>
          Link:{' '}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </div>
        <div className={classes.margin_top}>
          Permalink:{' '}
          <a href={permalink} target="_blank" rel="noopener noreferrer">
            {permalink}
          </a>
        </div>
        <div className={classes.margin_top}>
          <strong>Dodany do systemu:</strong> {moment(resource.createdAt).format('DD.MM.YYYY HH:mm:ss')}
        </div>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].title}
                  </div>
                  <div>
                    <strong>Pozyskanie:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].acquisition}
                  </div>
                  <div>
                    <strong>Datowanie:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].dating}
                  </div>
                  <div>
                    <strong>Gabaryty :</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].dimensions}
                  </div>
                  <div>
                    <strong>Sygnatura:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].signatures}
                  </div>
                  <div>
                    <strong>Napisy:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].subtitles}
                  </div>
                  <div>
                    <strong>Prawa autorskie:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].copyrights}
                  </div>
                  <div>
                    <strong>Historia:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].history}
                  </div>
                  <div>
                    <strong>Właściciel:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].owner}
                  </div>
                  <div>
                    <strong>Fundator:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].founder}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].description}
                  </div>
                  <div>
                    <strong>Cecha:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].feature}
                  </div>
                  <div>
                    <strong>Pierwowzór:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].archetype}
                  </div>
                  <div>
                    <strong>Analogie:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].analogy}
                  </div>
                  <div>
                    <strong>Cykl:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].cycle}
                  </div>
                  <div>
                    <strong>Nalepki:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].stickers}
                  </div>
                  <div>
                    <strong>Nazwa dod:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].additionalTitle}
                  </div>
                  <div>
                    <strong>Atrybucje:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].attribution}
                  </div>
                  <div>
                    <strong>Osoba oprac:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].personElaboration}
                  </div>
                  <div>
                    <strong>Waga:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].weight}
                  </div>
                  <div>
                    <strong>Wymiary dod:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].additionalDimensions}
                  </div>
                  <div>
                    <strong>Format:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].format}
                  </div>
                  <div>
                    <strong>Os:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].os}
                  </div>
                  <div>
                    <strong>Rzadkość:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].rarity}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].title}
                  </div>
                  <div>
                    <strong>Pozyskanie:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].acquisition}
                  </div>
                  <div>
                    <strong>Datowanie:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].dating}
                  </div>
                  <div>
                    <strong>Gabaryty :</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].dimensions}
                  </div>
                  <div>
                    <strong>Sygnatura:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].signatures}
                  </div>
                  <div>
                    <strong>Napisy:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].subtitles}
                  </div>
                  <div>
                    <strong>Prawa autorskie:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].copyrights}
                  </div>
                  <div>
                    <strong>Historia:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].history}
                  </div>
                  <div>
                    <strong>Właściciel:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].owner}
                  </div>
                  <div>
                    <strong>Fundator:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].founder}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].description}
                  </div>
                  <div>
                    <strong>Cecha:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].feature}
                  </div>
                  <div>
                    <strong>Pierwowzór:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].archetype}
                  </div>
                  <div>
                    <strong>Analogie:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].analogy}
                  </div>
                  <div>
                    <strong>Cykl:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].cycle}
                  </div>
                  <div>
                    <strong>Nalepki:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].stickers}
                  </div>
                  <div>
                    <strong>Nazwa dod:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].additionalTitle}
                  </div>
                  <div>
                    <strong>Atrybucje:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].attribution}
                  </div>
                  <div>
                    <strong>Osoba oprac:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].personElaboration}
                  </div>
                  <div>
                    <strong>Waga:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].weight}
                  </div>
                  <div>
                    <strong>Wymiary dod:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].additionalDimensions}
                  </div>
                  <div>
                    <strong>Format:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].format}
                  </div>
                  <div>
                    <strong>Os:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].os}
                  </div>
                  <div>
                    <strong>Rzadkość:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].rarity}
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="creators-content"
            id="creators-header"
          >
            Twórcy
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.creators.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.creator.translations[LANGS_IRI.PL].title} (
                  {resource.role.translations[LANGS_IRI.PL].title}),{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="materials-content"
            id="materials-header"
          >
            Tworzywa
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.materials.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.material.translations[LANGS_IRI.PL].title},{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="techniques-content"
            id="techniques-header"
          >
            Techniki
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.techniques.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.technique.translations[LANGS_IRI.PL].title},{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="places-content"
            id="places-header"
          >
            Miejsca
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.places.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.place.translations[LANGS_IRI.PL].title}, (
                  {resource.role.translations[LANGS_IRI.PL].title}),{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="types-content"
            id="types-header"
          >
            Rodzaje
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.types.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.type.translations[LANGS_IRI.PL].title},{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="exhibitions-content"
            id="exhibitions-header"
          >
            Wystawy
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.exhibitions.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${
                      resource.exhibition.translations[LANGS_IRI.EN].title
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      {resource.exhibition.translations[LANGS_IRI.PL].title}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="bibliographies-content"
            id="bibliographies-header"
          >
            Bibliografia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.bibliographies.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.publication.translations[LANGS_IRI.PL].description}{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].pages}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      strony: {resource.translations[LANGS_IRI.PL].pages}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="histories-content"
            id="histories-header"
          >
            Historia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.histories.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      tytuł: {resource.translations[LANGS_IRI.PL].title}
                    </span>
                  </Tooltip>
                  ,{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                  ,{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].time}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      czas: {resource.translations[LANGS_IRI.PL].time}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="keywords-content"
            id="keywords-header"
          >
            Słowa kluczowe
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.keywords.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.keyword.translations[LANGS_IRI.PL].title},{' '}
                  publikacja: {resource.stat ? 'TAK' : 'NIE'},{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="depicted-persons-content"
            id="depicted-persons-header"
          >
            Osoby przedstawione
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.depictedPersons.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="owners-content"
            id="owners-header"
          >
            Fundatorzy
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.owners.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].title
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      {resource.translations[LANGS_IRI.PL].title} ({translate(resource.type)})
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="images-content"
            id="images-header"
          >
            Zdjęcia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.images.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.media && (
                    <div>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.media.url}`}
                        className={classes.img}
                        alt={resource.uuid}
                      />
                    </div>
                  )}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].subtitle
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      Podpis: {resource.translations[LANGS_IRI.PL].subtitle}
                    </span>
                  </Tooltip>
                  , licencja: {resource.licence},{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].alt}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>alt: {resource.translations[LANGS_IRI.PL].alt}</span>
                  </Tooltip>
                  ,{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="multimedia-content"
            id="multimedia-header"
          >
            Multimedia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.multimedia.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                  , link:{' '}
                  <a
                    href={
                      resource.link ||
                      `${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.media?.url}`
                    }
                    target="blank"
                  >
                    {resource.link ||
                      `${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.media?.url}`}
                  </a>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="related-content"
            id="related-header"
          >
            Powiązane
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.related.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <Link
                  to={routes().view.path.replace(':id', resource.related.uuid)}
                >
                  {resource.related.translations[LANGS_IRI.PL].title}
                </Link>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="additional-descriptions-content"
            id="additional-descriptions-header"
          >
            Dodatkowe opisy
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.additionalDescriptions.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}: ${
                      resource.translations[LANGS_IRI.EN].text
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      {resource.translations[LANGS_IRI.PL].title}:{' '}
                      {resource.translations[LANGS_IRI.PL].text}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
}
