const schema = {
  endpoint: '/api/dictionary_materials',
  access: 'dictionaryMaterial',
  resource: {
    definition: '#DictionaryMaterial',
  },
  subresources: {
    alternations: {
      endpoint: '/api/dictionary_material_alternations',
    },
  },
}

export default schema
