import { useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { collectionActions } from '_actions/collection.actions'
import { notification } from '_helpers/notification'
import { constants } from 'pages/CollectionsThematic/components/choose_table/_state'
import buildUrl from 'build-url'

const STORE_COLLECTION_ID = 'choose_table'

const initState = ({ initialConfig, endpoint, filters, perPage }) => {
  const defaultConfig = {
    page: 1,
    perPage: perPage || parseInt(process.env.REACT_APP_COLLECTION_PER_PAGE),
    filters,
    rawFilters: filters,
  }

  return {
    collection: endpoint,
    storeCollectionId: STORE_COLLECTION_ID,
    data: {
      items: [],
      selected: [],
      selectAll: false,
      totalItems: 0,
      page: initialConfig
        ? initialConfig.page || defaultConfig.page
        : defaultConfig.page,
      perPage: initialConfig
        ? initialConfig.perPage || defaultConfig.perPage
        : defaultConfig.perPage,
    },
    config: initialConfig || defaultConfig,
    defaultConfig: defaultConfig,
    init: true,
    isFetching: false,
  }
}

export const useChooseTableFetch = (
  reducer,
  endpoint,
  filters = {},
  perPage = null
) => {
  const initialConfig = useSelector(
    state => state.collection[STORE_COLLECTION_ID]
  )

  const [state, stateDispatch] = useReducer(
    reducer,
    {
      initialConfig,
      endpoint,
      filters,
      perPage,
    },
    initState
  )

  const reduxDispatch = useDispatch()

  useEffect(() => {
    stateDispatch({ type: constants.FETCH_START })

    let query = {}

    for (const property in state.config.filters) {
      if (
        state.config.filters[property].value === null ||
        state.config.filters[property].value?.length < 1
      ) {
        continue
      }

      query[state.config.filters[property].name] = Array.isArray(
        state.config.filters[property].value
      )
        ? state.config.filters[property].value.join(',')
        : state.config.filters[property].value
    }

    const url = buildUrl(state.collection, {
      queryParams: {
        ...query,
        page: state.config.page,
        perPage: state.config.perPage,
      },
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        const data = {
          items: response['hydra:member'],
          selected: new Array(response['hydra:member'].length).fill(false),
          selectAll: false,
          totalItems: response['hydra:totalItems'],
          page: state.config.page,
          perPage: state.config.perPage,
        }

        stateDispatch({ type: constants.FETCH_SUCCESS, payload: { data } })
        reduxDispatch(
          collectionActions.setConfig(STORE_COLLECTION_ID, state.config)
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        stateDispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [reduxDispatch, state.config, state.collection])

  return [state, stateDispatch]
}
