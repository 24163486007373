const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      isExhibitAdditionalPropertiesVisible: {},
      isExhibitAdditionalDescriptionPropertyVisible: {},
      isExhibitFeaturePropertyVisible: {},
      isExhibitArchetypePropertyVisible: {},
      isExhibitAnalogyPropertyVisible: {},
      isExhibitCyclePropertyVisible: {},
      isExhibitStickersPropertyVisible: {},
      isExhibitAdditionalTitlePropertyVisible: {},
      isExhibitAttributionPropertyVisible: {},
      isExhibitPersonElaborationPropertyVisible: {},
      isExhibitWeightPropertyVisible: {},
      isExhibitAdditionalDimensionsPropertyVisible: {},
      isExhibitFormatPropertyVisible: {},
      isExhibitOsPropertyVisible: {},
      isExhibitRarityPropertyVisible: {},
      importerNotificationEmails: {
        type: 'textarea'
      },
    },
  },
}

export default schema
