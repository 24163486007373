import React from 'react'

export const MessageCell = ({ resource }) => (
  <div>
    <div>
      <b>Źródło:</b> {resource.originFileName}
    </div>
    <div>
      {resource.message}
    </div>
  </div>
)
