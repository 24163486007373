import React, { useCallback, useState } from 'react'
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import { ViewCollection } from 'pages/Exhibits/collection/ViewCollection'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/creator'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  margin_top: {
    marginTop: 20,
  },
  accordion_wrapper: {
    width: '100%',
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
  img: {
    maxWidth: 300,
  },
  tooltip: {
    cursor: 'pointer',
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const link = `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/tworca/${
    resource?.translations[LANGS_IRI.PL].slug
  }`

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <h2>{resource.translations[LANGS_IRI.PL].title}</h2>
        <h4>MONA ID: {resource.importId}</h4>
        <div className={classes.data}>
          {resource.media && (
            <img
              src={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.media.url}`}
              className={classes.img}
              alt={resource.uuid}
            />
          )}
          <div>
            <strong>Miejsce urodzenia: </strong>{' '}
            {resource.birthPlace && (
              <Tooltip
                title={`EN: ${
                  resource.birthPlace.translations[LANGS_IRI.EN].title
                }`}
                className={classes.tooltip}
                arrow
              >
                <span>
                  {resource.birthPlace.translations[LANGS_IRI.PL].title}
                </span>
              </Tooltip>
            )}
          </div>
          <div>
            <strong>Miejsce śmierci: </strong>{' '}
            {resource.deathPlace && (
              <Tooltip
                title={`EN: ${
                  resource.deathPlace.translations[LANGS_IRI.EN].title
                }`}
                className={classes.tooltip}
                arrow
              >
                <span>
                  {resource.deathPlace.translations[LANGS_IRI.PL].title}
                </span>
              </Tooltip>
            )}
          </div>
          <div>
            <strong>Rok urodzenia:</strong> {resource.birthYear}
          </div>
          <div>
            <strong>Rok śmierci:</strong> {resource.deathYear}
          </div>
          <div>
            <strong>Rok początku działalności:</strong> {resource.workStartYear}
          </div>
          <div>
            <strong>Rok końca działalności:</strong> {resource.workEndYear}
          </div>
        </div>
        <div className={classes.margin_top}>
          Link:{' '}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </div>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].title}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].description}
                  </div>
                  <div>
                    <strong>Uwagi:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].comments}
                  </div>
                  <div>
                    <strong>Data urodzenia:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].birthTime}
                  </div>
                  <div>
                    <strong>Data śmierci:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].deathTime}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Tytuł:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].title}
                  </div>
                  <div>
                    <strong>Opis:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].description}
                  </div>
                  <div>
                    <strong>Uwagi:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].comments}
                  </div>
                  <div>
                    <strong>Data urodzenia:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].birthTime}
                  </div>
                  <div>
                    <strong>Data śmierci:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].deathTime}
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="alternations-content"
            id="alternations-header"
          >
            Oboczności
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.alternations.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="nationalities-content"
            id="nationalities-header"
          >
            Narodowości
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.nationalities.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="roles-content"
            id="roles-header"
          >
            Role
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.roles.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.role.translations[LANGS_IRI.PL].title}, rok od:{' '}
                  {resource.yearFrom || ''}, rok do: {resource.yearTo || ''},{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].time}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      czas: {resource.translations[LANGS_IRI.PL].time}
                    </span>
                  </Tooltip>
                  ,{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="places-content"
            id="places-header"
          >
            Miejsca
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.places.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                  , rok od: {resource.yearFrom || ''}, rok do:{' '}
                  {resource.yearTo || ''},{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].time}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      czas: {resource.translations[LANGS_IRI.PL].time}
                    </span>
                  </Tooltip>
                  ,{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].role}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      rola: {resource.translations[LANGS_IRI.PL].role}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="bibliographies-content"
            id="bibliographies-header"
          >
            Bibliografia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.bibliographies.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.publication.translations[LANGS_IRI.PL].description}{' '}
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].pages}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      strony: {resource.translations[LANGS_IRI.PL].pages}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="sources-content"
            id="sources-header"
          >
            Źródła
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.sources.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.dictionary} {resource.title} ({resource.link})
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="keywords-content"
            id="keywords-header"
          >
            Słowa kluczowe
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.keywords.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  {resource.keyword.translations[LANGS_IRI.PL].title},{' '}
                  publikacja: {resource.stat ? 'TAK' : 'NIE'},{' '}
                  <Tooltip
                    title={`EN: ${
                      resource.translations[LANGS_IRI.EN].comments
                    }`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>
                      uwagi: {resource.translations[LANGS_IRI.PL].comments}
                    </span>
                  </Tooltip>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="multimedia-content"
            id="multimedia-header"
          >
            Multimedia
          </AccordionSummary>
          <AccordionDetails>
            <ViewCollection
              endpoint={schema.subresources.multimedia.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <div>
                  <Tooltip
                    title={`EN: ${resource.translations[LANGS_IRI.EN].title}`}
                    className={classes.tooltip}
                    arrow
                  >
                    <span>{resource.translations[LANGS_IRI.PL].title}</span>
                  </Tooltip>
                  , link:{' '}
                  <a href={resource.link} target="blank">
                    {resource.link}
                  </a>
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <div className={classes.margin_top}>Linki do sklepu</div>
        <EmbeddedCollection
          endpoint={schema.subresources.storeLinks.endpoint}
          parentIri={iri}
          properties={schema.subresources.storeLinks.properties}
          definitionSchema={
            definitions[schema.subresources.storeLinks.definition]
          }
          titleAccessor={resource =>
            resource.translations[LANGS_IRI.PL].link || 'brak linka'
          }
          statable={true}
        />
      </div>
    </Paper>
  )
}
