import React from 'react'
import clsx from 'clsx'
import { Grid, Paper } from '@material-ui/core'
import { Loader } from 'components/Loader'
import { UploadDialog } from 'components/form/fields/file/UploadDialog'
import { useThumbCollectionStyles, thumbCollectionPropTypes } from '.'
import { ThumbCropperWrapper } from './ThumbCropperWrapper'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { getCrop } from './_helpers/getCrop'
import { getConfig } from './_helpers/getConfig'
import { recalculateCrop } from './_helpers/recalculateCrop'
import { recalculateConfig } from './_helpers/recalculateConfig'
import { useThumbCollectionFetch } from './_helpers/useThumbCollectionFetch'
import mediaSchema from '_schema/media'

export const ThumbCollection = ({
  endpoint,
  parentIri,
  originalPhotoUrl,
  photoProperty = 'template',
  photoEndpoint = mediaSchema.endpoint,
  uploadValidators = ['maxSize'],
  disabled = false,
  twoColLayout = true,
  cropperNodeRef = null,
  align = 'center',
  classes = {},
}) => {
  const [state, setState] = useThumbCollectionFetch(endpoint, parentIri)

  const handleThumbChange = index => () => {
    if (state.isFetching || disabled) {
      return
    }

    setState(state => ({
      ...state,
      currentThumb: index,
    }))
  }

  const handleFullScreen = () => {
    setState(state => ({
      ...state,
      isFullScreen: !state.isFullScreen,
    }))
  }

  const handleUploadDialogToggle = () => {
    setState(state => ({
      ...state,
      isUploadDialogOpen: !state.isUploadDialogOpen,
    }))
  }

  const setCrop = index => newCrop => {
    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i === index ? { ...item, crop: newCrop } : item
      ),
    }))
  }

  const setOriginal = index => dimensions => {
    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i !== index
          ? item
          : {
              ...item,
              original: dimensions,
              crop:
                JSON.stringify(item.original) === JSON.stringify(dimensions)
                  ? item.crop
                  : recalculateCrop(
                      dimensions,
                      getCrop(item.resource),
                      'frame'
                    ),
              config:
                JSON.stringify(item.original) === JSON.stringify(dimensions)
                  ? item.config
                  : recalculateConfig(
                      dimensions,
                      getConfig(item.resource),
                      'frame'
                    ),
            }
      ),
    }))
  }

  const handlePhotoChange = index => resource => {
    const recalculatedCrop = recalculateCrop(
      state.items[index].original,
      {
        x: resource.cropX,
        y: resource.cropY,
        width: resource.cropWidth,
        height: resource.cropHeight,
        aspect: resource.width / resource.height,
      },
      'frame'
    )
    const recalculatedConfig = recalculateConfig(
      state.items[index].original,
      {
        minWidth: resource.width,
        minHeight: resource.height,
        maxWidth: resource.width,
        maxHeight: resource.height,
      },
      'frame'
    )

    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i === index
          ? {
              resource,
              crop: recalculatedCrop,
              config: recalculatedConfig,
            }
          : item
      ),
    }))
  }

  const handleSubmit = (type, index) => () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    let body

    if (type === 'save') {
      const recalculatedCrop = recalculateCrop(
        state.items[index].original,
        state.items[index].crop,
        'natural'
      )

      body = {
        cropX: Math.round(recalculatedCrop.x),
        cropY: Math.round(recalculatedCrop.y),
        cropWidth: Math.round(recalculatedCrop.width),
        cropHeight: Math.round(recalculatedCrop.height),
      }
    } else {
      body = {
        isResetRequired: true,
      }
    }

    fetchDataHandleAuthError(
      state.items[index].resource['@id'],
      'PUT',
      { body: JSON.stringify(body) },
      response => {
        const recalculatedCrop = recalculateCrop(
          state.items[index].original,
          {
            x: response.cropX,
            y: response.cropY,
            width: response.cropWidth,
            height: response.cropHeight,
            aspect: response.width / response.height,
          },
          'frame'
        )
        const recalculatedConfig = recalculateConfig(
          state.items[index].original,
          {
            minWidth: response.width,
            minHeight: response.height,
            maxWidth: response.width,
            maxHeight: response.height,
          },
          'frame'
        )

        setState(state => ({
          ...state,
          items: state.items.map((item, i) =>
            i === index
              ? {
                  ...item,
                  resource: response,
                  crop: recalculatedCrop,
                  config: recalculatedConfig,
                }
              : item
          ),
          isFetching: false,
        }))

        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const defaultClasses = useThumbCollectionStyles()

  return (
    <div className={clsx(classes.root)}>
      {(state.init || state.isFetching) && (
        <Loader align={align} marginBottom={15} />
      )}
      {!state.init &&
        (state.items.length ? (
          <div
            className={clsx(
              twoColLayout && defaultClasses.twoColLayout,
              twoColLayout && classes.twoColLayout
            )}
          >
            <Grid
              container
              spacing={2}
              className={clsx(classes.thumbsContainer)}
            >
              {state.items.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={twoColLayout ? 4 : 3}
                  key={`thumb-${index}`}
                >
                  <Paper
                    onClick={handleThumbChange(index)}
                    className={clsx(
                      defaultClasses.thumbImageContainer,
                      classes.thumbImageContainer,
                      index === state.currentThumb &&
                        defaultClasses.thumbImageContainerCurrent,
                      (state.isFetching || disabled) &&
                        defaultClasses.thumbImageContainerDisabled
                    )}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_BACKEND_ENTRYPOINT}${item.resource.url}?v=${item.resource.version})`,
                      }}
                      className={clsx(
                        defaultClasses.thumbImage,
                        classes.thumbImage,
                        (state.isFetching || disabled) &&
                          defaultClasses.thumbImageDisabled
                      )}
                    />
                  </Paper>
                  <div
                    className={clsx(
                      defaultClasses.thumbTitle,
                      classes.thumbTitle
                    )}
                  >
                    {translate(
                      `T_THUMB_${endpoint
                        .replace(/^\//, '')
                        .toUpperCase()}_${item.resource.name.toUpperCase()}`
                    )}
                    &nbsp;
                    {`${item.resource.width} x ${item.resource.height}`}
                  </div>
                </Grid>
              ))}
            </Grid>
            <ThumbCropperWrapper
              nodeRef={cropperNodeRef}
              originalPhotoUrl={
                state.items[state.currentThumb].resource[photoProperty]?.url ||
                originalPhotoUrl
              }
              crop={state.items[state.currentThumb].crop}
              minWidth={state.items[state.currentThumb].config.minWidth}
              minHeight={state.items[state.currentThumb].config.minHeight}
              maxWidth={state.items[state.currentThumb].config.maxWidth}
              maxHeight={state.items[state.currentThumb].config.maxHeight}
              setCrop={setCrop(state.currentThumb)}
              setOriginal={setOriginal(state.currentThumb)}
              handleReset={handleSubmit('reset', state.currentThumb)}
              handleSubmit={handleSubmit('save', state.currentThumb)}
              handleUploadDialogToggle={handleUploadDialogToggle}
              handleFullScreen={handleFullScreen}
              disabled={state.isFetching || disabled}
              isFullScreen={state.isFullScreen}
              classes={{
                root: classes.cropContainer,
                fullScreen: classes.cropContainerFullScreen,
                additionalButtons: classes.cropContainerAdditionalButtons,
                fullScreenButton: classes.cropContainerFullScreenButton,
                submitButtons: classes.cropContainerSubmitButtons,
                resetButton: classes.cropContainerResetButton,
                crop: classes.cropContainerCrop,
                cropFullScreen: classes.cropContainerCropFullScreen,
              }}
              key={`${state.isFullScreen ? 'full-screen' : 'default-view'}-${
                state.items[state.currentThumb].resource.uuid
              }`}
            />
            <UploadDialog
              name={photoProperty}
              type="image"
              endpoint={photoEndpoint}
              disabled={disabled}
              validators={uploadValidators}
              setValue={() => {}}
              setUpdatedAt={() => {}}
              formUrl={state.items[state.currentThumb].resource['@id']}
              formMethod="PUT"
              formProcessResponse={handlePhotoChange(state.currentThumb)}
              isOpen={state.isUploadDialogOpen}
              handleToggle={handleUploadDialogToggle}
              key={`upload-dialog-${
                state.items[state.currentThumb].resource.uuid
              }`}
            />
          </div>
        ) : (
          <div
            className={clsx(defaultClasses.emptyResults, classes.emptyResults)}
          >
            {translate('T_GENERAL_PHOTO_THUMBS_EMPTY')}
          </div>
        ))}
    </div>
  )
}

ThumbCollection.propTypes = thumbCollectionPropTypes
